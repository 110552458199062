import React from "react"
import styled from "styled-components"
import Layout from "../components/layout"
import "react-vertical-timeline-component/style.min.css"
import "react-big-calendar/lib/css/react-big-calendar.css"
import AddStudentForm from "../components/addStudentForm"
import SendMessageForm from "../components/sendMessageForm"

const Messages = () => {
  return (
    <Layout>
      <CustomDiv>
        <SendMessageForm/>
      </CustomDiv>
    </Layout>
  )
}
export default Messages

//styled-components
const CustomDiv = styled.div`
margin-left: 40px;
margin-top: 40px;
margin-bottom: 140px ;
`
