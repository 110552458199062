import { useDispatch } from "react-redux"
import React from "react"
import { navigate } from "gatsby"
import { MuiPickersUtilsProvider } from "@material-ui/pickers"
import MomentUtils from "@date-io/moment"
import TextField from "@material-ui/core/TextField"
import { ButtonStyled } from "./styles/styles"
import styled from "styled-components"
import ArrowBack from "@material-ui/icons/ArrowBackIos"
import { sendMessage } from "../redux/firebaseAction"

const SendMessageForm = () => {
  const dispatch = useDispatch()
  const [error, setError] = React.useState(false)
  const [form, setForm] = React.useState({
    message_from: "",
    message_to: "",
    message: "",
  })
  const handleChange = event => {
    setForm({ ...form, [event.target.name]: event.target.value })
  }
  const handleSave = form => {
    if (
      form.message_from.length !== 0 &&
      form.message_to.length !== 0 &&
      form.message.length !== 0
    ) {
      dispatch(sendMessage(form.message_from, form.message_to, form.message))
      navigate("/")
    } else {
      setError(true)
    }
  }
  const handleClearForm = () => {
    setForm({
      message_from: "",
      message_to: "",
      message: "",
    })
  }
  return (
    <>
      <MuiPickersUtilsProvider utils={MomentUtils}>
        <table>
          <tbody>
            <CustomTr>
              <td>
                <ArrowBack onClick={() => navigate("/messages")} />
              </td>
              <td></td>
            </CustomTr>
          </tbody>
        </table>
        <CustomForm>
          <form
            /* style={{ width: 600, margin: "5%" }} */
            noValidate
            autoComplete="off"
          >
            <TextField
              id="standard-basic"
              name="message_from"
              error={error}
              /* placeholder="Location of the event" */
              /* fullWidth */
              value={form.message_from}
              onChange={handleChange}
              label="From"
              variant="outlined"
            />
            <TextField
              id="standard-basic"
              name="message_to"
              error={error}
              /* placeholder="Location of the event" */
              /* fullWidth */
              required
              value={form.message_to}
              onChange={handleChange}
              label="Message to: (email address)"
              variant="outlined"
            />
            <TextField
              id="standard-basic"
              name="message"
              /* placeholder="Links to the event,organizer etc" */
              value={form.message}
              /* fullWidth */
              onChange={handleChange}
              label="Message"
              variant="outlined"
              multiline
              rows={4}
              rowsMax={4}
            />
            {/* </div> */}
            <div style={{ textAlign: "right" }}>
              <ButtonStyled
                text="black"
                bg="white"
                onClick={() => handleClearForm()}
              >
                Cancel
              </ButtonStyled>
              <ButtonStyled onClick={() => handleSave(form)}>Send</ButtonStyled>
            </div>
          </form>
        </CustomForm>
      </MuiPickersUtilsProvider>
    </>
  )
}
export default SendMessageForm

//styled-components
const CustomForm = styled.div`
  .MuiFormGroup-root {
    padding: 0 10px;
  }
  .MuiTextField-root {
    width: 100%;
    margin: 10px;
  }
  form {
    // width:50%
    margin: 0 25%;
  }
  form label,
  input,
  h2,
  label,
  span,
  input,
  textarea {
    font-size: 16px;
    font-family: "GT-Walsheim-Regular", sans-serif;
  }
  .MuiDialog-paper {
    min-width: 20%;
  }
  // span {
  //   font-size: 12px;
  // }
  svg {
    width: 0.6em;
  }
`
const CustomTr = styled.tr`
  background-color: black;
  color: white;
`
